<!--
 * @Date: 2024-01-17 15:58:31
 * @LastEditTime: 2024-01-17 17:47:52
 * @Description: file content
-->
<template>
  <div class="navbar-wrap">
    <div class="navbar">
      <div class="navbar-content-left">
        <!-- logo -->
        <span class="logo" @click="goHome"> </span>
        <div class="vertical-line"></div>
        <div class="title">应用code管理</div>
      </div>

      <div class="navbar-content-right">
        <!-- <div class="service-console-button" @click="jumpToServiceConsole">
          我的服务
        </div> -->
        <!-- 登陆后显示 用户名、头像 -->
        <template v-if="isLoggedIn">
          <div class="head-user-nickname">
            <div class="singe-line">{{ userInfo.userName }}，欢迎您</div>
            <div class="nickname-curve"></div>
          </div>
          <div
            class="avatar"
            @click.stop.prevent="isShowLogoutBar = !isShowLogoutBar"
          >
            <!-- <img :src="userInfo.headImg" /> -->
            <img src="@/assets/img/nav/default-avatar.png" />
            <div v-show="isShowLogoutBar" @click.stop class="logout-bar">
              <div class="user-info">
                <!-- <img :src="userInfo.headImg" /> -->
                <img src="@/assets/img/nav/default-avatar.png" />
                {{ userInfo.userName }}
              </div>

              <div class="logout-button" @click="exit">
                <div class="icon-power"></div>
                退出
              </div>
            </div>
          </div>
        </template>

        <!-- 未登录 -->
        <!-- <div v-else class="login" @click="logIn">登录 / 注册</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'CodeNavbar',
  data() {
    return {
      userAccountInfo: {},
      isShowLogoutBar: false,
      isLoggedIn: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('user-info', ['getUserInfo']),
    async init() {
      const token = localStorage.getItem('tokenKey');
      if (token) {
        try {
          const res = await this.getUserInfo();
          this.isLoggedIn = true;
        } catch (e) {
          this.exit();
          console.log(1);
        }
      }
    },
    exit() {
      localStorage.clear();
      this.$router.push({
        path: '/login',
      });
    },
    jumpToServiceConsole() {
      window.location.href = 'https://aishipgo.com/manage/#/my-service';
    },
    goHome() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.navbar-wrap {
  padding-bottom: 60px;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background-color: #100527;
  z-index: 10;

  // navbar 左侧 logo title
  .navbar-content-left {
    display: flex;
    align-items: center;
    padding-left: 20px;

    .logo {
      position: relative;
      display: inline-block;
      // width: 197px;
      // height: 28px;
      width: 127px;
      height: 18px;
      margin-right: 56px;
      // left: -16px;
      background-image: url(../../assets/img/logo-light.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
    }

    .vertical-line {
      margin-right: 15px;
      width: 1px;
      height: 16px;
      background-color: #9b98a7;
    }

    .title {
      height: 20px;
      opacity: 0.65;

      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }

  // navbar 右侧服务控制台，昵称头像
  .navbar-content-right {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 32px;

    .service-console-button {
      width: 102px;
      height: 32px;
      border-radius: 800px;

      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 300;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      background-color: #281e3d;
      cursor: pointer;
      &:hover {
        color: #6c38e0;
        background: rgba(0, 0, 0, 0.2);
      }
    }

    .head-user-nickname {
      position: relative;
      // left: 122px;
      width: 126px;
      height: 32px;
      line-height: 20px;
      margin-left: 20px;
      padding: 6px 16px;
      font-size: 14px;
      background: rgba(240, 242, 245);
      border-radius: 100px 50px 0 100px;
      color: #fff;
      z-index: 887;
      background-color: #281e3d;
      box-sizing: border-box;

      .singe-line {
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
      .nickname-curve {
        position: absolute;
        top: 0;
        right: -21px;
        width: 21px;
        height: 32px;
        background-image: url(../../assets/img/curve-dark.png);
        background-repeat: no-repeat;
        background-size: 153px auto;
        background-position: 100%;
      }
    }

    // 头像
    .avatar {
      position: relative;
      // left: 122px;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      background-color: rgba(240, 242, 245);
      z-index: 888;
      box-sizing: border-box;
      border: 1px solid #c4c8c9;
      padding: 1px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 32px;
      }

      // 用户登出框
      .logout-bar {
        position: absolute;
        right: 0;
        min-width: 200px;
        top: calc(100% + 28px);
        background: #fff;
        border: 1px solid #dededf;
        border-radius: 5px;
        font-size: 14px;
        color: #000;
        cursor: default;

        .user-info {
          display: flex;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid #dededf;
          white-space: nowrap;

          img {
            width: 48px;
            height: 48px;
            border-radius: 50px;
            margin-right: 8px;
          }
        }

        .logout-button {
          display: flex;
          align-items: center;
          margin: 3px 4px;
          padding: 10px 0 13px 13px;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            color: #ed4233;
            background: rgba(237, 66, 51, 0.15);

            .icon-power {
              background-image: url(../../assets/img/power-hover.png);
            }
          }
          &:active {
            color: #be3528;
            background: rgba(237, 66, 51, 0.15);

            .icon-power {
              background-image: url(../../assets/img/power-click.png);
            }
          }

          .icon-power {
            width: 16px;
            height: 17px;
            background-image: url(../../assets/img/power.png);
            background-size: 100%;
            margin-right: 20px;
          }
        }
      }
    }

    .login {
      color: #333;
      border: 1px solid #333;
      border-radius: 4px;
      font-size: 12px;
      padding: 3px 5px;
      z-index: 999;
      background: none;
      margin-right: 32px;
      cursor: pointer;

      border-color: #fff;
      color: #fff;

      &:hover {
        border: 1px solid transparent;
        background: linear-gradient(
          to bottom,
          rgb(38, 13, 188, 0.7),
          rgb(66, 69, 207, 0.7)
        );
        color: #fff;
      }
    }
  }
}
</style>
