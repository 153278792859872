<template>
  <div class="listCode">
    <Viewheader class="full-width" :isHome="false" ref="navheader" />
    <div class="listCodeMain">
      <div class="codeNav">
        <leftNav />
      </div>
      <div class="codeList">
        <div class="codeName">code管理</div>
        <div class="yPcklQR listCodeYP">
          <div>
            <div class="yPcklQRModel">
              <span class="selectTitle">业务方：</span>
              <el-select
                v-model="ruleForm.bizUnit"
                placeholder="请选择业务方"
                @change="provinceTypes"
              >
                <el-option
                  v-for="item in bizUnitListVal"
                  :key="item.value"
                  :label="item.bizUnitName"
                  :value="item.bizUnit"
                >
                </el-option>
              </el-select>
            </div>

            <div class="yPcklQRModel">
              <span class="selectTitle">状态：</span>
              <el-select
                v-model="ruleForm.bizStatus"
                placeholder="状态选择"
                @change="provinceTypeStatus"
              >
                <el-option label="有效" value="1"></el-option>
                <el-option label="失效" value="0"></el-option>
              </el-select>
            </div>
            <div class="yPcklQRModelText">
              <span class="selectTitle">工程接口：</span>
              <el-input
                v-model="ruleForm.projectInterfaceLike"
                placeholder="工程接口+模糊搜"
                v-on:input="bizPageOn"
                style="width: 200px"
              ></el-input>
              <div
                class="selectprojectInterface"
                v-if="projectInterfaceLikeTre"
              >
                <span
                  v-for="(item, index) in projectInterfaceLikeResult"
                  :key="index"
                  @click="selectprojectInterfaceValue(item.projectInterface)"
                  >{{ item.projectInterface }}</span
                >
              </div>
            </div>

            <div class="yPcklQRModelText" style="margin-left: 20px">
              <span class="selectTitle">创建人：</span>
              <el-input
                v-model="ruleForm.operatorLike"
                placeholder="创建人+模糊搜"
                v-on:input="bizPageOnOperatorLike"
                style="width: 200px"
              ></el-input>
              <div
                class="selectprojectInterface"
                v-if="bizPageOnOperatorLikeTre"
              >
                <span
                  v-for="(item, index) in operatorLikeResult"
                  :key="index"
                  @click="selectoperatorValue(item.operator)"
                  >{{ item.operator }}</span
                >
              </div>
            </div>
            <!-- <div> -->
            <el-button
              type="primary"
              class="retrieval"
              @click="searchButton"
              style="margin-left: 20px"
              >检索</el-button
            >
            <el-button type="primary" class="retrieval" @click="rest"
              >重置</el-button
            >
            <el-button type="primary" class="retrieval" @click="addCode"
              >创建code</el-button
            >
            <!-- </div> -->
          </div>
          <div class="tableCode">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              :cell-class-name="tableCellClassName"
              @row-dblclick="dbclick"
            >
              <el-table-column prop="bizUnit" label="业务方" width="120">
              </el-table-column>
              <el-table-column prop="bizName" label="项目名" width="150">
              </el-table-column>
              <el-table-column prop="bizStatusVal" label="code状态" width="100">
              </el-table-column>

              <el-table-column
                prop="createTimeVal"
                label="code创建时间"
                width="180"
              >
              </el-table-column>
              <el-table-column
                prop="bizCode"
                label="bizcode"
                width="180"
                :show-overflow-tooltip="true"
              >
              </el-table-column>

              <el-table-column label="工程接口" width="140">
                <template slot-scope="scope">
                  <a @click="engineeringInterface()">{{
                    scope.row.projectInterface
                  }}</a>
                </template>
              </el-table-column>

              <el-table-column prop="bizDemander" label="需求人" width="150">
              </el-table-column>
              <el-table-column prop="operator" label="code创建人" width="120">
              </el-table-column>

              <el-table-column
                prop="validityVal"
                label="调用有效期"
                width="120"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <a @click="callValidityPeriod()">{{
                    scope.row.validityVal
                  }}</a>
                  <el-date-picker
                    v-model="selectDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    style="width: 83%"
                    @change="dateTime($event, scope.row.id)"
                    class="selectDate"
                    :picker-options="pickerOptions"
                    :clearable="false"
                  >
                  </el-date-picker>
                </template>
              </el-table-column>

              <el-table-column
                prop="weekCallCount"
                label="实际-近七日均调/次"
                width="150"
              >
              </el-table-column>
              <el-table-column prop="qpsMax" label="max-qps" width="120">
              </el-table-column>
              <el-table-column
                prop="callMax"
                label="max-调用量/万次"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="dayCallMax"
                label="每日峰值调用量/次"
                width="140"
              >
              </el-table-column>

              <el-table-column
                label="项目备注"
                width="250"
                prop="remark"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <!--v-if去判断双击的是不是当前单元格-->
                  <el-input
                    @blur="updateNotesValue(scope.row)"
                    :ref="scope.row.index + ',' + scope.column.index"
                    v-model="scope.row.remark"
                    v-if="
                      scope.row.index + ',' + scope.column.index == currentCell
                    "
                  >
                  </el-input>
                  <span v-else>{{ scope.row.remark }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="deactivateCode(scope.row.id)"
                    v-if="scope.row.bizStatus == 1"
                  >
                    code停用
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    v-if="scope.row.bizStatus == 0"
                    style="color: #8f959e"
                  >
                    code已停用
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="codePages">
            <el-pagination
              :current-page="currentPage"
              :page-size="pageSize"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Viewheader from '@/components/viewheader/codeNavbar.vue';
import Footering from '../../components/foot/viewfooter.vue';
import leftNav from '../../views/code/leftNav.vue';
import '../../assets/css/code.css';
import { bizUnitList, bizCreate, bizPage, bizUpdate } from '@/api/aidata.js';
export default {
  components: {
    Viewheader,
    Footering,
    leftNav,
  },
  data() {
    return {
      title: '关于我们',
      contents: [
        'AIShipGo是一个专注于跨境电商人工智能技术与解决方案的产品技术平台。凭借着海量的电商数据、国际领先的算法和工程能力，以“让跨境电商的生意更简单、更高效”为目标，致力于为跨境卖家提供全面的人工智能技术支持，解决社媒引流、站内营销、风控安全、直播带货等业务问题。',
        '团队由一群经验丰富的AI专家和跨境电商从业者组成，深入了解跨境电商行业的挑战和机遇，结合先进的NLP、CV、多模态、ML等AIGC技术，为卖家提供全方位的解决方案。产品均已在真实跨境业务中应用，后续将持续开放更多能力。提供网页、SDK、私有化部署等对接方式，满足不同的应用需求，请关注我们，了解更多的最新动态。',
      ],
      name: '',
      selectDate: '',
      BusinessPartyValue: '全部',
      statusValue: '全部',
      tooltip: true,
      ruleForm: {
        // 业务方
        bizUnit: '',
        // 业务名
        bizName: '',

        bizStatus: '',
        operatorLike: '',
        projectInterfaceLike: '',
      },
      projectInterfaceLikeTre: false,
      bizPageOnOperatorLikeTre: false,
      // 用一个字符串来保存当前双击的是哪一个单元格
      currentCell: null,
      tableData: [],
      bizUnitListVal: '',
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示数量
      total: 10, // 总记录数
      pickerOptions: {
        shortcuts: [
          {
            text: '长期',
            onClick(picker) {
              const start = new Date(1970, 0, 1);
              picker.$emit('pick', [start, start]);
            },
          },
        ],
        validityLongStatus: '',
      },
    };
  },
  mounted() {
    // 获取业务方列表
    this.bizUnitListClick();
    this.listSearch(
      this.ruleForm.bizUnit,
      this.ruleForm.bizStatus,
      this.ruleForm.projectInterfaceLike,
      this.ruleForm.operatorLike,
      this.currentPage,
      this.pageSize,
    );
  },
  methods: {
    // 业务方列表
    bizUnitListClick() {
      bizUnitList({}).then((res) => {
        if (res.data.state === '0x0000') {
          this.bizUnitListVal = res.data.data;
          // console.log(this.bizUnitListVal)
        } else if (res.data.state === '0x0008') {
          this.$message({
            message: res.data.message,
            type: 'error',
            duration: 2000,
            onClose: () => {
              this.$refs.navheader.logOn();
            },
          });
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
          });
        }
      });
    },
    // 获取业务方的值
    provinceTypes(value) {
      this.bizUnitListVal.forEach((item) => {
        if (item.bizUnit === value) {
          this.ruleForm.bizUnit = item.bizUnit;
          console.log(this.ruleForm.bizUnit);
        }
      });
    },
    // 状态
    provinceTypeStatus(value) {
      this.ruleForm.bizStatus = value;
      console.log(this.ruleForm.bizStatus);
    },
    // 业务方下拉选
    // BusinessParty(command) {
    //   this.BusinessPartyValue = command
    // },
    // 状态下拉选
    status(command) {
      this.statusValue = command;
    },
    // 创建code
    addCode() {
      this.$router.push('/addCode').then(() => {
        // 页面跳转后要执行的操作
        this.$router.go(0);
      });
    },

    // 工程接口
    engineeringInterface() {
      console.log('工程接口');
    },
    // code 停用
    deactivateCode(id) {
      this.$confirm('此操作会影响当前业务方，接口使用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          bizUpdate({
            id: id,
            bizStatus: parseInt(0),
          }).then((res) => {
            if (res.data.state === '0x0000') {
              this.$message({
                type: 'success',
                message: '已停用!',
              });
              this.listSearch(
                this.ruleForm.bizUnit,
                this.ruleForm.bizStatus,
                this.ruleForm.projectInterfaceLike,
                this.ruleForm.operatorLike,
                this.currentPage,
                this.pageSize,
              );
            } else if (res.data.code === '404') {
              // this.$message({
              //     message: res.data.msg,
              //     type: "error",
              //     offset: window.screen.height / 2
              // });
              // this.$refs.navheader.logOn()
            } else {
              this.$message({
                message: res.data.message,
                type: 'error',
              });
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消',
          //   offset: window.screen.height / 2
          // });
        });
    },
    // 调用有效期
    callValidityPeriod() {
      console.log('调用有效期');
    },
    // 监听时间
    dateTime(val, id) {
      console.log(val[0]);
      console.log(val[1]);
      // console.log(val)
      // console.log(id)
      if (val[0] == '1970-01-01') {
        this.validityLongStatus = parseInt(1);
      } else {
        this.validityLongStatus = parseInt(2);
        var validityStartValue = new Date(val[0]);
        var validityStartValueA = validityStartValue.toString();
        var validityEndValue = new Date(val[1]);
        var validityEndValueA = validityEndValue.toString();
      }
      this.selectDate = '';

      bizUpdate({
        id: id,
        validityStart: validityStartValueA ? validityStartValueA : undefined,
        validityEnd: validityEndValueA ? validityEndValueA : undefined,
        validityStatus: this.validityLongStatus,
      }).then((res) => {
        if (res.data.state === '0x0000') {
          this.listSearch(
            this.ruleForm.bizUnit,
            this.ruleForm.bizStatus,
            this.ruleForm.projectInterfaceLike,
            this.ruleForm.operatorLike,
            this.currentPage,
            this.pageSize,
          );
          this.$message({
            type: 'success',
            message: '修改成功!',
          });
        } else if (res.data.code === '404') {
          // this.$message({
          //     message: res.data.msg,
          //     type: "error",
          //     offset: window.screen.height / 2
          // });
          // this.$refs.navheader.logOn()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
          });
        }
      });
    },

    // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
    // 拼接后类似这样："1,0","1,1",
    dbclick(row, column) {
      this.currentCell = row.index + ',' + column.index;
      // 这里必须要setTimeout，因为在点击的时候，input才刚被v-if显示出来，不然拿不到dom
      setTimeout(() => {
        // 双击后自动获得焦点
        this.$refs[row.index + ',' + column.index].focus();
        // row增加一个新的参数，将原先的值赋给新的参数，用来比较是否进行更改
        this.$set(row, 'newNotesValue', row.remark);
      });
    },
    // 当input失去焦点的时候，隐藏input, 将修改的内容传给后台
    updateNotesValue(row) {
      // console.log(row)
      this.currentCell = null;
      // console.log(this.currentCell)
      // 比较这个单元格的值是否进行了更改，没有更改不进行任何操作，更改了才触发接口
      if (row.remark !== row.newNotesValue) {
        bizUpdate({
          id: row.id,
          remark: row.remark,
        }).then((res) => {
          if (res.data.state === '0x0000') {
            this.$message({
              type: 'success',
              message: '修改成功!',
            });
          } else if (res.data.code === '404') {
            // this.$message({
            //     message: res.data.msg,
            //     type: "error",
            //     offset: window.screen.height / 2
            // });
            // this.$refs.navheader.logOn()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      } else {
        console.log('修改失败');
      }
    },

    // 工程接口 模糊搜索
    bizPageOn(val) {
      console.log(val);
      if (val) {
        this.projectInterfaceLikeTre = false;
      }
      bizPage({
        projectInterfaceLike: val,
      }).then((res) => {
        if (res.data.state === '0x0000') {
          console.log(res.data.data.page.result);
          if (res.data.data.page.result.length == 0) {
            this.projectInterfaceLikeTre = false;
          } else {
            if (val == '') {
              this.projectInterfaceLikeTre = false;
            } else {
              this.projectInterfaceLikeTre = true;
            }
            this.projectInterfaceLikeResult = res.data.data.page.result;
          }
        } else if (res.data.code === '404') {
          // this.$message({
          //     message: res.msg,
          //     type: "error",
          //     offset: window.screen.height / 2
          // });
          // this.$refs.navheader.logOn()
        } else {
          this.$message({
            message: res.message,
            type: 'error',
          });
        }
      });
    },
    // 点击选中
    selectprojectInterfaceValue(val) {
      this.ruleForm.projectInterfaceLike = val;
      console.log(this.ruleForm.projectInterfaceLike);
      this.projectInterfaceLikeTre = false;
    },

    // 操作人 模糊搜索
    bizPageOnOperatorLike(val) {
      console.log(val);

      bizPage({
        operatorLike: val,
      }).then((res) => {
        if (res.data.state === '0x0000') {
          console.log(res.data.data.page.result);
          if (res.data.data.page.result.length == 0) {
            this.bizPageOnOperatorLikeTre = false;
          } else {
            if (val == '') {
              this.bizPageOnOperatorLikeTre = false;
            } else {
              this.bizPageOnOperatorLikeTre = true;
            }
            this.operatorLikeResult = res.data.data.page.result;
          }
        } else if (res.data.code === '404') {
          // this.$message({
          //     message: res.data.message,
          //     type: "error",
          //     offset: window.screen.height / 2
          // });
          // this.$refs.navheader.logOn()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error',
          });
        }
      });
    },
    // 点击选中
    selectoperatorValue(val) {
      this.ruleForm.operatorLike = val;
      console.log(this.ruleForm.operatorLike);
      this.bizPageOnOperatorLikeTre = false;
    },

    // 获取当前页
    handleCurrentChange(page) {
      this.currentPage = page;
      console.log(page);
      this.listSearch(
        this.ruleForm.bizUnit,
        this.ruleForm.bizStatus,
        this.ruleForm.projectInterfaceLike,
        this.ruleForm.operatorLike,
        this.currentPage,
        this.pageSize,
      );
    },
    // 检索
    searchButton() {
      this.currentPage = 1;
      this.listSearch(
        this.ruleForm.bizUnit,
        this.ruleForm.bizStatus,
        this.ruleForm.projectInterfaceLike,
        this.ruleForm.operatorLike,
        this.currentPage,
        this.pageSize,
      );
    },
    // 重置
    rest() {
      this.ruleForm.bizUnit = '';
      this.ruleForm.bizStatus = '';
      this.ruleForm.projectInterfaceLike = '';
      this.ruleForm.operatorLike = '';
      this.listSearch(
        this.ruleForm.bizUnit,
        this.ruleForm.bizStatus,
        this.ruleForm.projectInterfaceLike,
        this.ruleForm.operatorLike,
        this.currentPage,
        this.pageSize,
      );
    },
    // 默认接口列表页
    listSearch(
      bizUnit,
      bizStatus,
      projectInterfaceLike,
      operatorLike,
      pageNum,
      pageSize,
    ) {
      // console.log(bizUnit)
      // console.log(bizStatus)
      // console.log(projectInterfaceLike)
      // console.log(operatorLike)
      // console.log(pageNum)
      // console.log(pageSize)

      bizPage({
        bizUnit: bizUnit ? bizUnit : undefined,
        bizStatus: bizStatus ? parseInt(bizStatus) : undefined,
        projectInterfaceLike: projectInterfaceLike
          ? projectInterfaceLike
          : undefined,
        operatorLike: operatorLike ? operatorLike : undefined,
        pageNum: parseInt(pageNum),
        pageSize: parseInt(pageSize),
      }).then((res) => {
        if (res.data.state === '0x0000') {
          // 总条数
          this.total = res.data.data.page.pageBean.count;
          // console.log(res.data.data.page.result)
          this.tableData = res.data.data.page.result;
          // console.log(res.data.data.page.result)
          // code创建时间
          for (var i = 0; i < res.data.data.page.result.length; i++) {
            // code创建时间
            let dateString = this.dateTypeFormat(
              'YYYY-mm-dd HH:MM:SS',
              new Date(res.data.data.page.result[i].createTime),
            );
            this.$set(
              res.data.data.page.result[i],
              'createTimeVal',
              dateString,
            );

            // validityStatus有效期状态
            // console.log(res.data.data.page.result[i].validityStatus)
            if (res.data.data.page.result[i].validityStatus == 1) {
              this.$set(
                res.data.data.page.result[i],
                'validityVal',
                '长期有效',
              );
            } else {
              let dateValidityStartString = this.dateTypeFormat(
                'YYYY-mm-dd',
                new Date(res.data.data.page.result[i].validityStart),
              );
              let datevalidityEndString = this.dateTypeFormat(
                'YYYY-mm-dd',
                new Date(res.data.data.page.result[i].validityEnd),
              );
              this.$set(
                res.data.data.page.result[i],
                'validityVal',
                dateValidityStartString + ' 至 ' + datevalidityEndString,
              );
            }
            // code状态
            if (res.data.data.page.result[i].bizStatus == 1) {
              this.$set(res.data.data.page.result[i], 'bizStatusVal', '有效');
            } else {
              this.$set(res.data.data.page.result[i], 'bizStatusVal', '失效');
            }
          }
          // console.log(this.tableData)
        } else if (res.data.code === '404') {
          // this.$message({
          //     message: res.data.msg,
          //     type: "error",
          //     offset: window.screen.height / 2
          // });
          // this.$refs.navheader.logOn()
        } else {
          // this.$message({
          //   message: res.data.message,
          //   type: 'error',
          // })
        }
      });
    },
  },
};
</script>
<style scoped>
.listCodeMain {
  height: calc(100vh - 60px);
}
.listCodeYP {
  height: calc(100vh - 140px);
  overflow-y: auto;
}
/* .listCodeYP .el-table_1_column_6 {
  color: #777AED;
  text-decoration:underline;
  cursor: pointer;
} */
.listCodeYP .el-table th.el-table__cell > .cell {
  color: #909399;
}
.listCodeYP .el-table_1_column_9 {
  color: #777aed;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
}
.listCodeYP .el-table_1_column_14 {
  cursor: pointer;
}
.listCodeYP .el-button--text {
  font-size: 14px !important;
}
.selectDate {
  position: absolute;
  top: 10px;
  left: 0;
  opacity: 0;
}
.listCodeYP .el-date-editor {
  position: absolute;
}
.projectNotes {
  width: 90%;
  outline: none;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #d0d3d6;
  position: absolute;
  left: 4%;
  /* padding: 0px 3%; */
  top: 15px;
  color: #909399;
}
::v-deep .el-table__body-wrapper .el-table__body {
  font-size: 12px;
}
</style>
